import { required } from '@/@core/utils/validation'
import profile from '@/api/profile/profile'
import shop from '@/api/systemSetting/shop'
import store from '@/store'
import { ref } from '@vue/composition-api'

export default function useSetupClini() {
  const shopData = ref({})
  const loading = ref(true)
  const blob_image = ref(null)
  const processUpload = ref(false)
  const processUpdateShop = ref(false)
  const tokenUrl = ref(`https://line-login-aps.vercel.app/?token=${localStorage.getItem('token')}`)
  const lineId = ref('https://line.me/R/ti/p/@192amftv')
  const formUpdateShop = ref(null)
  const currentTab = ref(0)
  const isApikey = ref(false)
  const isSecretkey = ref(false)
  const profileData = ref({})
  const tabs = ref([
    { title: 'shop_information' },
    { title: 'datacompany' },
    { title: 'docsComment' },
    { title: 'PAY SOLUTION' },
  ])

  const { shopGet, uploadImage, shopUpdate } = shop

  shopGet().then(res => {
    shopData.value = res
    localStorage.setItem('paySolutions', res.paysolutions_active)
    loading.value = false
    blob_image.value = res.shop_image
  })

  profile.getProfile().then(res => {
    profileData.value = res
  })

  const previewFiles = event => {
    shopData.value.shop_image = event.target.files[event.target.files.length - 1]
    blob_image.value = URL.createObjectURL(event.target.files[event.target.files.length - 1])
    processUpload.value = true
    updateImage()
  }

  const updateImage = () => {
    const formData = new FormData()
    formData.append('image', shopData.value.shop_image)
    uploadImage(formData).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      store.commit('app/setStatusUpdateHeader')
      processUpload.value = false
    })
  }

  const updateShopData = () => {
    const isFormValid = formUpdateShop.value.validate()
    if (!isFormValid) return
    processUpdateShop.value = true
    loading.value = true
    shopUpdate(shopData.value).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      processUpdateShop.value = false
      shopGet().then(res1 => {
        localStorage.setItem('paySolutions', res1.paysolutions_active)
        console.log('pay', res1.paysolutions_active)
        shopData.value = res1
        loading.value = false
        blob_image.value = res1.shop_image
      })
    })
  }

  return {
    isApikey,
    isSecretkey,
    processUpdateShop,
    formUpdateShop,
    updateShopData,
    processUpload,
    tabs,
    tokenUrl,
    currentTab,
    blob_image,
    shopUpdate,
    lineId,
    loading,
    shopData,
    profileData,
    previewFiles,
    required,
  }
}
