<template>
  <div id='user-view'>
    <v-row>
      <v-col cols='12' md='6' lg='3'>
        <v-card class='px-3 py-3 d-flex' :loading='loading'>
          <v-avatar size='50' class='me-3 primary elevation-5'>
            <v-icon color='#fff' large>
              {{ icons.mdiHome }}
            </v-icon>
          </v-avatar>
          <div>
            <ConverseDate class='font-weight-semibold' :date='shopData.shop_start' />
            <br />
            {{ $t('shop_creation_date') }}
          </div>
        </v-card>
      </v-col>
      <v-col cols='12' md='6' lg='3'>
        <v-card class='px-3 py-3 d-flex' :loading='loading'>
          <v-avatar size='50' class='me-3 info elevation-5'>
            <v-icon color='#fff' large>
              {{ icons.mdiCubeSend }}
            </v-icon>
          </v-avatar>
          <div>
            <ConverseDate class='font-weight-semibold' :date='shopData.shop_package' />
            <br />
            {{ $t('last_updated_package_date') }}
          </div>
        </v-card>
      </v-col>
      <v-col cols='12' md='6' lg='3'>
        <v-card class='px-3 py-3 d-flex' :loading='loading'>
          <v-avatar size='50' class='me-3 error elevation-5'>
            <v-icon large color='#fff'>
              {{ icons.mdiGiftOutline }}
            </v-icon>
          </v-avatar>
          <div>
            <ConverseDate class='font-weight-semibold' :date='shopData.shop_expire' />
            <br />
            {{ $t('date_package_expires') }}
          </div>
        </v-card>
      </v-col>
      <v-col cols='12' md='6' lg='3'>
        <v-card class='px-3 py-3 d-flex' :loading='loading'>
          <v-avatar size='50' class='me-3 warning elevation-5'>
            <v-icon color='#fff' large>
              {{ icons.mdiEmailOutline }}
            </v-icon>
          </v-avatar>
          <div>
            <span class='font-weight-semibold'> {{ shopData.shop_sms_all | formatPrice }}</span
            ><br />
            {{ $t('remaining_sms_credit') }}
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-card :loading='loading' class='mt-2'>
      <v-card-title class='py-0'>
        {{ $t('Setup clinic') }}
        <v-spacer></v-spacer>
        <span class='error--text'>{{ $t('balance') }} : {{ shopData.packages_expire }} {{ $t('day')
          }}</span>
        <v-spacer></v-spacer>
        <v-switch
          v-model='shopData.paysolutions_active'
          value='shopData.paysolutions_active'
          color='primary'
          :true-value="'1'"
          class='me-2'
          :false-value="'0'"
          :label="$t('SettingPaymentWithPaySolutions')"
          @change='updateShopData'
        ></v-switch>
        <!--        <v-switch-->
        <!--          v-model="shopData.shop_print_type"-->
        <!--          value="shopData.printId"-->
        <!--          color="primary"-->
        <!--          :true-value="2"-->
        <!--          :false-value="1"-->
        <!--          :label="$t('pritfromClinicOrCompany')"-->
        <!--          class="mb-0"-->
        <!--          @change="updateShopData"-->
        <!--        ></v-switch>-->
      </v-card-title>
      <v-divider class='mb-2'></v-divider>
      <v-form ref='formUpdateShop' @submit.prevent='updateShopData'>
        <v-card-text>
          <v-row>
            <v-col cols='12' md='4' lg='3'>
              <v-card-text align='center'>
                <v-avatar rounded size='150' class='me-2' color='#2b2b2b'>
                  <v-img contain :src='blob_image'></v-img>
                </v-avatar>
                <!-- upload photo -->
                <div>
                  <v-btn
                    class='mt-6'
                    :loading='processUpload'
                    :disabled='processUpload'
                    color='primary'
                    @click='$refs.refInputEl.click()'
                  >
                    <v-icon class='d-sm-none'>
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                    <span class='d-none d-sm-block'>{{ $t('upload_pictures') }}</span>
                  </v-btn>

                  <input
                    ref='refInputEl'
                    type='file'
                    accept='.jpeg,.png,.jpg,GIF'
                    :hidden='true'
                    @change='previewFiles'
                  />
                  <p class='text-sm mt-5'>
                    {{ $t('function_features_5') }}
                  </p>
                </div>
                <v-row>
                  <v-col align='left' cols='12'>
                    {{ $t('shop_type') }} :
                    <v-chip color='info' label small class='v-chip-light-bg info--text'>
                      {{ shopData.shop_type_id == '1' ? $t('main_shop') : $t('sub_shop') }}
                    </v-chip>
                  </v-col>
                  <v-col align='left' cols='12'>
                    {{ $t('shop_status') }} :
                    <v-chip color='info' label small class='v-chip-light-bg info--text'>
                      {{ $t('normal') }}
                    </v-chip>
                  </v-col>
                  <v-col align='left' cols='12'>
                    {{ $t('allow') }} website :
                    <v-chip color='info' label small class='v-chip-light-bg info--text'>
                      {{ shopData.shop_web_id == '1' ? $t('enable') : $t('unable') }}
                    </v-chip>
                  </v-col>
                  <v-col align='left' cols='12'>
                    <a href='https://www.clinicpro.app/' target='_bank'> {{ $t('request_to_join') }}
                      clinicPro : </a>
                    <v-chip color='info' label small class='v-chip-light-bg info--text'>
                      {{ shopData.shop_pro_id == '0' ? $t('not_request') : $t('requested') }}
                    </v-chip>
                  </v-col>
                  <v-col align='left' cols='12'>
                    {{ $t('allow') }} clinicPro :
                    <v-chip color='info' small label class='v-chip-light-bg info--text'>
                      {{ $t('allow') }}
                    </v-chip>
                  </v-col>
                  <v-col v-if="profileData.user_type_id == '1'" align='left' cols='12'>
                    {{ $t('statusConnectList') }} :
                    <v-chip
                      v-if='shopData.shop_connect_line_status == 1'
                      color='info'
                      small
                      label
                      class='v-chip-light-bg info--text'
                    >
                      {{ $t('connect') }}
                    </v-chip>
                    <v-chip
                      v-if='shopData.shop_connect_line_status == 0'
                      color='error'
                      small
                      label
                      class='v-chip-light-bg error--text'
                    >
                      {{ $t('Notconnect') }}
                    </v-chip>
                  </v-col>
                  <v-col v-if="profileData.user_type_id == '1'" cols='12'>
                    <VueQrcode :value='tokenUrl' :options='{ width: 200 }'></VueQrcode>
                    <br />
                    {{ $t('lineNotify') }} <br />
                    <a href='https://line.me/R/ti/p/@192amftv' target='_blank'>
                      {{ $t('linkAddFriend') }}
                    </a>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col cols='12' md='8' lg='9' align='center'>
              <v-tabs v-model='currentTab' show-arrows class='user-tabs'>
                <v-tab v-for='tab in tabs' :key='tab.icon'>
                  <span>{{ $t(tab.title) }}</span>
                </v-tab>
              </v-tabs>
              <v-tabs-items id='user-tabs-content' v-model='currentTab'>
                <v-tab-item transition='none'>
                  <v-row class='mt-1'>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_id'
                        outlined
                        dense
                        hide-details='auto'
                        readonly
                        :label="$t('shop_code')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_name'
                        outlined
                        :rules='[required]'
                        hide-details='auto'
                        dense
                        :label="$t('shop_name')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_nature'
                        outlined
                        hide-details='auto'
                        dense
                        :label="$t('nature_service')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_license'
                        outlined
                        hide-details='auto'
                        dense
                        :label="$t('license_number')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_address'
                        outlined
                        hide-details='auto'
                        dense
                        :label="$t('address')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_district'
                        outlined
                        dense
                        hide-details='auto'
                        :label="$t('sub_district')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_amphoe'
                        outlined
                        dense
                        hide-details='auto'
                        :label="$t('district')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_province'
                        outlined
                        hide-details='auto'
                        dense
                        :label="$t('province')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_zipcode'
                        outlined
                        hide-details='auto'
                        dense
                        :label="$t('zip_code')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_tel'
                        outlined
                        dense
                        hide-details='auto'
                        :label="$t('tel')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_email'
                        outlined
                        dense
                        hide-details='auto'
                        :label="$t('email')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_promptpay_id'
                        outlined
                        dense
                        hide-details='auto'
                        label='Promptpay ID'
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_fax'
                        outlined
                        hide-details='auto'
                        dense
                        :label="$t('fax')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_line'
                        outlined
                        dense
                        hide-details='auto'
                        label='Line ID'
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_facebook'
                        outlined
                        hide-details='auto'
                        dense
                        label='Facebook'
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_instagram'
                        outlined
                        hide-details='auto'
                        dense
                        label='Instagram'
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_drug_lowest'
                        outlined
                        hide-details='auto'
                        dense
                        type='number'
                        :label="$t('specify_number_of_drugs')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_drug_expire'
                        outlined
                        hide-details='auto'
                        dense
                        type='number'
                        :label="$t('specify_the_numbe_of_days')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols='12' lg='6' align='center' justify='center'>
                      <v-img width='180' class='rounded-lg elevation-2'
                             :src="shopData.qrcode || ''"></v-img>
                    </v-col>
                    <v-col cols='12' lg='6'>
                      <v-text-field
                        v-model='shopData.shop_promptpay_id'
                        label='Promptpay ID'
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        v-model='shopData.shop_promptpay_name'
                        :label="$t('PromptpayName')"
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        v-model='shopData.shop_point_exchange'
                        :suffix="$t('bath')"
                        outlined
                        hide-details='auto'
                        dense
                      >
                        <template v-slot:label>
                          <span>
                            {{ $t('points_earned_1_point') }}
                          </span>
                          <span class='error--text ml-1'>{{ $t('redeem_points_as_discount')
                            }}</span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item transition='none'>
                  <v-row class='mt-1'>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_company_name'
                        outlined
                        hide-details='auto'
                        dense
                        :label="$t('company_name')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_tax'
                        outlined
                        hide-details='auto'
                        dense
                        :label="$t('tax_id')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_company_address'
                        outlined
                        hide-details='auto'
                        dense
                        :label="$t('address')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_company_district'
                        outlined
                        dense
                        hide-details='auto'
                        :label="$t('sub_district')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_company_amphoe'
                        outlined
                        dense
                        hide-details='auto'
                        :label="$t('district')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_company_province'
                        outlined
                        hide-details='auto'
                        dense
                        :label="$t('province')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_company_zipcode'
                        outlined
                        hide-details='auto'
                        dense
                        :label="$t('zip_code')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_company_tel'
                        outlined
                        dense
                        hide-details='auto'
                        :label="$t('tel')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='4'>
                      <v-text-field
                        v-model='shopData.shop_company_email'
                        outlined
                        dense
                        hide-details='auto'
                        :label="$t('email')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item transition='none'>
                  <v-textarea v-model='shopData.shop_comment' outlined dense rows='7'></v-textarea>
                </v-tab-item>
                <v-tab-item transition='none'>
                  <v-row class='mt-1'>
                    <v-col cols='12' md='6'>
                      <v-text-field
                        dense
                        outlined
                        v-model='shopData.paysolutions_merchant'
                        label='Merchant ID :'
                        placeholder='Merchant ID'
                        hide-details='auto'
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6'>
                      <v-text-field
                        dense
                        v-model='shopData.paysolutions_secretkey'
                        outlined
                        :type="isSecretkey ? 'text' : 'password'"
                        :append-icon='isSecretkey ? icons.mdiEyeOffOutline : icons.mdiEyeOutline'
                        @click:append='isSecretkey = !isSecretkey'
                        label='Secret Key :'
                        placeholder='Secret Key'
                        hide-details='auto'
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6'>
                      <v-text-field
                        dense
                        outlined
                        :type="isApikey ? 'text' : 'password'"
                        :append-icon='isApikey ? icons.mdiEyeOffOutline : icons.mdiEyeOutline'
                        @click:append='isApikey = !isApikey'
                        label='API Key :'
                        v-model='shopData.paysolutions_apikey'
                        placeholder='API Key'
                        hide-details='auto'
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
              <v-btn color='primary' type='submit' class='mt-3' :loading='loading'
                     :disabled='loading'>
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import {
  mdiCloudUploadOutline,
  mdiHome,
  mdiCubeSend,
  mdiGiftOutline,
  mdiEmailOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
} from '@mdi/js'
import useSetupClinic from './useSetupClinic'
import { sumdate, formatPrice } from '@/plugins/filters'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import VueQrcode from '@chenfengyuan/vue-qrcode'

export default {
  components: {
    ConverseDate,
    VueQrcode,
  },
  filters: { sumdate, formatPrice },
  setup() {
    return {
      ...useSetupClinic(),
      icons: {
        mdiCloudUploadOutline,
        mdiHome,
        mdiCubeSend,
        mdiGiftOutline,
        mdiEmailOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>
<style lang='scss'>
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  padding: 8px;
}

.ql-container {
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  height: 200px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 0px;
  position: relative;
}

@import '@core/preset/preset/apps/user.scss';
</style>
