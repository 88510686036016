import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const getProfile = async () => {
  await refreshToken()
  const response = await api
    .get({
      path: 'profile',
    })
    .then(res => (res.data ? res.data : {}))
    .catch(err => {
      console.error('get profile err : ', err)

      return {}
    })

  return response
}
const updateProfile = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'profile',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('update profile err : ', err)

      return {}
    })

  return response
}
const uploadProfileImage = async image => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'profile/uploadimage',
      body: image,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get profile image err : ', err)

      return {}
    })

  return response
}
const logoutGoogle = async () => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'profile/glogout',
    })
    .then(res => res || {})
    .catch(err => {
      console.error('logout google+ err : ', err)

      return {}
    })

  return response
}
const changePassword = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'profile/password',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('change password err : ', err)

      return {}
    })

  return response
}
const changeConfirmPassword = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'profile/key',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('change confirm password err : ', err)

      return {}
    })

  return response
}
const updateBasicDoctorData = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'profile/doctor',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('Update basic doctor data err : ', err)

      return {}
    })

  return response
}
const insertEducation = async (body, path) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: `profile/${path}`,
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('Insert Education err : ', err)

      return {}
    })

  return response
}
const updateEducation = async (body, path) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: `profile/${path}`,
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('Update education err : ', err)

      return {}
    })

  return response
}
const deleteEducation = async (id, path) => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: `profile/${path}`,
      param: id,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('Delete Education err : ', err)

      return {}
    })

  return response
}

const openDay = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'profile/sectionday',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('Open Section day err : ', err)

      return {}
    })

  return response
}
const closeDay = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'profile/sectionday',
      param: id,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('Close Section day err : ', err)

      return {}
    })

  return response
}
const openTime = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'profile/section',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('Open Section Time err : ', err)

      return {}
    })

  return response
}
const closeTime = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'profile/section',
      param: id,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('Close Section Time err : ', err)

      return {}
    })

  return response
}

export default {
  getProfile,
  updateProfile,
  uploadProfileImage,
  logoutGoogle,
  changePassword,
  changeConfirmPassword,
  updateBasicDoctorData,
  insertEducation,
  updateEducation,
  deleteEducation,
  openDay,
  closeDay,
  openTime,
  closeTime,
}
